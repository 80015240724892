export enum URLSearchParamsKey {
  // If current path is in provider details and user is not authorized
  // then this key is used to set the current path as a query param and pass it to login route
  Forward = 'fwd',
  Status = 'status',
  UseLastLocationOnAccept = 'useLastLocationOnAccept',
  LastLocation = 'll',
  Path = 'path',
  FileId = 'fileId',
  OrgId = 'orgId',
  AmaMergeSuccess = 'amaMergeSuccess',
  ScrollTarget = 'target'
}
