import Routes from './Route';

export const FailedVerificationTab = {
  Dataset: 'dataset'
  // License: 'license'
};

export const getFailedVerificationTabAsList = (): CommonList[] => {
  return [
    {
      id: FailedVerificationTab.Dataset,
      label: 'S&E and other credentials',
      key: Routes.IssueFailedDatasetVerification
    }
    // {
    //   id: FailedVerificationTab.License,
    //   label: 'Failed License Verifications',
    //   key: Routes.IssueFailedLicenseVerification
    // }
  ];
};
