import { URLSearchParamsKey } from './URLSearchParamsKey';

enum Routes {
  Auth = '/auth',
  Login = '/login',
  CredLogin = '/cred-login',
  ForgotPassword = '/forgot-password',
  NewPassword = '/new-password',
  ResetPassword = '/reset-password',

  DiscoverSearch = '/discover/search',
  DiscoverProviderProfile = '/discover/provider-profile',
  DiscoverLicenseVerification = '/discover/license-verification',

  ChangeOrg = '/change-organization',
  OrgPicker = '/org-picker',

  Network = '/my-network',
  Discover = '/discover',
  Analytics = '/analytics',
  Cvo = '/cvo-requests',

  Providers = '/my-network/providers',
  NeedsReview = '/my-network/needs-review',
  LicenseNeedsReview = '/my-network/needs-review/license',
  DeaNeedsReview = '/my-network/needs-review/dea',
  BoardNeedsReview = '/my-network/needs-review/board',
  SanctionsAndExclusionReview = '/my-network/needs-review/sanctions',
  NpdbReview = '/my-network/needs-review/npdb',
  NpiReview = '/my-network/needs-review/npi',
  CertificateReview = '/my-network/needs-review/certificate',
  ProfileReview = '/my-network/needs-review/profile',
  ProfileImport = '/my-network/needs-review/profile/import',
  ProfileMerge = '/my-network/needs-review/profile/merge',

  ProviderLicense = '/my-network/provider-license',
  AllProviderLicense = '/my-network/provider-license?status=All',
  Issues = '/my-network/issues',
  IssueSanctions = '/my-network/issues/sanctions',
  IssueNpdb = '/my-network/issues/npdb',
  IssueFailedVerification = '/my-network/issues/failed-verifications',
  IssueFailedDatasetVerification = '/my-network/issues/failed-verifications/dataset',
  IssueFailedLicenseVerification = '/my-network/issues/failed-verifications/license',

  MonitoringAlerts = '/my-network/issues/monitoring-alerts',
  Processing = '/my-network/processing',

  CvoRequests = '/cvo-requests/all',
  CvoCancellationRequests = '/cvo-requests/cancellation-request',
  CvoAssignedRequests = '/cvo-requests/assigned',
  CvoNeedsInfo = '/cvo-requests/needs-additional-info',
  CvoReCredentialingSoon = '/cvo-requests/re-credentialing-soon',

  ProviderDetail = '/provider',
  ProviderDetailNotes = '/provider/%providerId%/notes',
  ProviderDetailOverview = '/provider/%providerId%/overview',
  ProviderDetailBasicInfo = '/provider/%providerId%/basic-info',
  ProviderDetailCaqh = '/provider/%providerId%/caqh',
  ProviderDetailAma = '/provider/%providerId%/ama',
  ProviderDetailCvo = '/provider/%providerId%/cvo',
  ProviderDetailEnrollment = '/provider/%providerId%/enrollment',
  ProviderDetailEnrollmentGroups = '/provider/%providerId%/enrollment/groups',
  ProviderDetailEnrollmentPayers = '/provider/%providerId%/enrollment/payers',
  ProviderDetailNpi = '/provider/%providerId%/npi',
  ProviderDetailDea = '/provider/%providerId%/dea',
  ProviderDetailLicense = '/provider/%providerId%/license',
  ProviderDetailBoard = '/provider/%providerId%/board',
  ProviderDetailCertificate = '/provider/%providerId%/certificate',
  ProviderDetailInsurance = '/provider/%providerId%/insurance',
  ProviderDetailEducation = '/provider/%providerId%/education',
  ProviderDetailWorkHistory = '/provider/%providerId%/work-history',
  ProviderDetailTraining = '/provider/%providerId%/training',
  ProviderDetailSanction = '/provider/%providerId%/sanction',
  ProviderDetailSanctionArchive = '/provider/%providerId%/sanction/archive',
  ProviderDetailSanctionRejected = '/provider/%providerId%/sanction/rejected',
  ProviderDetailAudit = '/provider/%providerId%/audit',
  ProviderDetailFile = '/provider/%providerId%/file',
  ProviderDetailMonitoring = '/provider/%providerId%/monitoring',
  ProviderDetailNpdb = '/provider/%providerId%/npdb',

  ProviderCaqhMerge = '/provider/%providerId%/caqh-merge',
  ProviderCaqhMergeRecordReview = '/provider/%providerId%/caqh-merge/%recordId%/review',
  ProviderCaqhMergeRecordResolve = '/provider/%providerId%/caqh-merge/%recordId%/resolve',
  ProviderCaqhMergeRecordAdd = '/provider/%providerId%/caqh-merge/%recordId%/add',

  ProviderAmaMerge = '/provider/%providerId%/ama-merge',
  ProviderAmaMergeRecordAdd = '/provider/%providerId%/ama-merge/%recordId%/add',
  ProviderAmaMergeRecordReview = '/provider/%providerId%/ama-merge/%recordId%/review',

  Review = '/review',
  ReviewLicense = '/review/%providerId%/license',
  ReviewDea = '/review/%providerId%/dea',
  ReviewBoard = '/review/%providerId%/board',
  ReviewNpi = '/review/%providerId%/npi',
  ReviewAlert = '/review/alert',
  ReviewCertificate = '/review/%providerId%/certificate',

  Settings = '/settings',
  Account = '/settings/account',
  MyAccount = '/settings/account/my-account',
  Team = '/settings/account/team',
  Coverage = '/settings/coverage',
  CoverageLicense = '/settings/coverage/license',
  CoverageBoard = '/settings/coverage/board',
  AuditLogs = '/settings/audit-logs',

  Print = '/print',
  PrintLicense = '/print/%providerId%/license',
  PrintDea = '/print/%providerId%/dea',
  PrintNpi = '/print/%providerId%/npi',
  PrintSanction = '/print/%providerId%/sanction',
  PrintBoard = '/print/%providerId%/board',
  PrintEducation = '/print/%providerId%/education',
  PrintCertificate = '/print/%providerId%/certificate',
  PrintTraining = '/print/%providerId%/training',

  Group = '/groups',
  GroupDetail = '/group',
  GroupDetailOverview = '/group/%groupId%/detail/overview',
  GroupDetailPayers = '/group/%groupId%/detail/overview/payers',
  GroupDetailProviders = '/group/%groupId%/detail/overview/providers',
  GroupDetailPayerDetail = '/group/%groupId%/detail/payer',
  SelectProviders = '/select-providers',

  Maintenance = '/maintenance',
  NotFound = '/404-not-found',

  ConsoleApp = '/temp-token-login',
  NotSupport = '/not-support'
}

export default Routes;

export const unauthorisedRoutes: string[] = [
  Routes.Auth,
  Routes.Login,
  Routes.CredLogin,
  Routes.ForgotPassword,
  Routes.ResetPassword,
  Routes.NewPassword,
  Routes.Maintenance,
  Routes.ConsoleApp
];

export const xFeatureRoutes: string[] = [];

export const resolve = (primaryRoute: Routes, ...args: string[]): string => {
  return [primaryRoute, ...args].join('/');
};

export const addLastLocationParam = (
  primaryRoute: string,
  useLastlocationOnAccept?: boolean
): string => {
  if (!primaryRoute) {
    return '';
  }
  let route = `${primaryRoute}${primaryRoute.includes('?') ? '&' : '?'}ll=${location.pathname}`;
  useLastlocationOnAccept && (route += `&${URLSearchParamsKey.UseLastLocationOnAccept}=true`);
  return route;
};

export const getUrlSearchParam = (key: string): string => {
  const queryParam = new URLSearchParams(location.search);
  return queryParam.get(key) || '';
};

export const constructQueryParam = (params: ObjectType<any>): string => {
  const queryParam = new URLSearchParams(location.search);
  Object.keys(params).forEach((key) => {
    if (!params[key] && !queryParam.has(key)) {
      return;
    }
    if (params[key] && queryParam.has(key)) {
      queryParam.set(key, params[key]);
    } else if (!params[key] && queryParam.has(key)) {
      queryParam.delete(key);
    } else {
      queryParam.append(key, params[key]);
    }
  });
  return `?${queryParam.toString()}`;
};

export const getProviderAuditNavUrl = (
  providerId: string,
  verificationType: string,
  entityId?: string
): string => {
  if (!providerId) throw new Error('Invalid Provider ID');

  const queryParam = new URLSearchParams();
  verificationType && queryParam.set('v', verificationType);
  entityId && queryParam.set('entityId', entityId);

  const query = queryParam.toString() ? `?${queryParam.toString()}` : '';
  return `${Routes.ProviderDetailAudit.replace('%providerId%', providerId)}${query}`;
};
